<template>
  <!-- <img height="100%" src="../../assets/conteudos_casas_32.png" /> -->
  <div>
    <section class="container section__margin-bottom">
      <v-row class="d-flex justify-center flex-column align-center">
        <div class="d-flex align-self-start pt-2 pb-2">
          <Breadcrumb class="conteudos_tematicos_section_bc-color">
            <template v-slot:breadcrumbLinks>
              <router-link
                tag="p"
                :to="{ name: link.href }"
                v-for="(link, idx) in breadcrumbItems"
                :key="idx"
              >
                {{ link.text }}
              </router-link>
            </template>
          </Breadcrumb>
        </div>
        <div
          class="d-flex align-self-center justify-center align-center mt-md-5"
        >
          <img
            width="40%"
            src="../../assets/conteudos_casas_home_03.png"
            alt=""
          />
        </div>
      </v-row>
    </section>

    <section class="container section__margin-bottom">
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" md="10" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card
              borderColor="#426E51"
              titleColor="#C92F05"
              title="Saberes tradicionais, sociabilidade e reprodução social através dos quintais"
              title_alignment="center"
            >
              <template v-slot:conteudo>
                <p>
                  Os efeitos provocados pela desterritorialização das
                  comunidades atingidas pelo rompimento da barragem de Fundão
                  são diversos, acarretando profundas mudanças sociais. Dentre
                  tais mudanças estão as rupturas dos vínculos entrelaçados com
                  e através dos quintais.
                </p>
              </template>
            </Card>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="container section__margin-bottom">
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" md="7" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card borderColor="#426E51" titleColor="#426E51">
              <template v-slot:conteudo>
                <p>
                  Os quintais são espaços utilizados para a produção de
                  alimentos, convívio familiar, trabalho, lazer, entre outros.
                  Nesse contexto, constituem-se também como um elemento central
                  da agricultura familiar desenvolvida pelos moradores das
                  comunidades atingidas. São reveladores da especificidade das
                  práticas produtivas, experiências de sociabilidade e formas de
                  organização do trabalho familiar. Ademais, as práticas de
                  cultivo e cuidado com os quintais evidenciam a complexidade de
                  seus saberes que conectam gerações.
                </p>
                <p>
                  Para além do provimento da casa como garantia do
                  <router-link
                    tag="a"
                    style="text-decoration: none"
                    class="pink--text"
                    :to="{ name: 'conteudos_tematicos_cultura_alimentar' }"
                    >consumo doméstico</router-link
                  >, a produção dos quintais circulava entre os núcleos de
                  vizinhança e membros da família, conformando, assim, redes de
                  troca e reciprocidade mediadas pelos alimentos, conforme
                  indica a narrativa de uma moradora de Paracatu de Baixo:
                  “plantava horta, se eu tinha chuchu e minha vizinha não tinha,
                  eu trocava com ela em alface” (GESTA¹, 2017). Em Paracatu de
                  Baixo, tais fluxos que envolviam alimentos, afetos, cortesias
                  e regalos vinculavam também essa comunidade aos moradores de
                  outras localidades, como a própria sede de Mariana.
                </p>
              </template>
            </Card>
          </div>
        </v-col>
        <v-col
          cols="12"
          md="5"
          class="d-flex justify-center align-center flex-column"
        >
          <img height="100%" src="../../assets/conteudos_casas_25.png" />
          <p style="text-align: center; font-size: 0.6em;" class="mt-1">
            Bancos debaixo das árvores de um quintal em Paracatu de Baixo, onde
            os vizinhos vinham se juntar à família da D. Benigna depois que
            terminavam o serviço. Foto: Vistoria realizada pela Cáritas, 2018.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container section__margin-bottom">
      <v-row class="d-flex justify-center align-center">
        <v-col
          cols="10"
          md="9"
          class="d-flex justify-center align-center border__image"
        >
          <p style="z-index: 15; text-align: center" class="ma-0">
            Vinha gente de Mariana buscar aqui. Vinha pra comprar, mas eu não
            vendia, não. Mas eles davam as coisas pra mim, sabe? [...] Mas quem
            mais buscava verdura aqui era Adriana. Que ela tinha uma feirinha,
            né? Aí eu falava: “Ô Adriana, pode levar, boba”. Mas ela trazia as
            coisas pra mim. O que eu não tinha aqui, ela trazia. Ela trazia pão,
            refrigerante, linguiça, salame, que ela trazia, batata, abóbora,
            trazia pra mim (GESTA, 2017, p. 14).
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container section__margin-bottom">
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" md="6" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card borderColor="#426E51" titleColor="#426E51">
              <template v-slot:conteudo>
                <p>
                  Nesse território, as hortas eram cercadas por bambus através
                  de uma técnica valorizada enquanto elemento distintivo da
                  comunidade: “a cerca de bambu, em Paracatu tudo tinha” (GESTA,
                  2017). A construção da cerca de bambu fazia parte de um
                  conhecimento local compartilhado entre gerações por meio da
                  oralidade: “a cerca de bambu é no meu quintal e minha mãe que
                  me ensinou, a vó, a bisavó que ensinou ela” (GESTA, 2017, p.
                  13). O esmero, a especificidade dos procedimentos e as
                  habilidades requeridas são explicitadas por um morador da
                  localidade que compartilhou conosco os métodos usados para
                  garantir a durabilidade do material por pelo menos oito anos:
                </p>
              </template>
            </Card>
          </div>
        </v-col>
        <v-col
          cols="12"
          md="6"
          class="d-flex justify-center align-center flex-column"
        >
          <img width="80%" src="../../assets/conteudos_casas_26.png" />
          <p
            style="text-align: center; font-size: 0.6em; max-width: 100%"
            class="mt-1"
          >
            Plantação de quiabo. Foto: Acervo Gesta, 2017.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container section__margin-bottom">
      <v-row class="d-flex justify-center align-center">
        <v-col
          cols="10"
          md="9"
          class="d-flex justify-center align-center border__image"
        >
          <p style="z-index: 15; text-align: center" class="ma-0">
            É tudo amarrado com arame. Amarrado um a um. [...] Dá trabalho
            demais pra fazer. [...] Isso aí com ele rachado e aberto, tirado ele
            na lua certa, demora oito a dez anos. Tirando na lua certa. [...] A
            melhor lua é a crescente, né? Você tira na lua crescente pra não dar
            broca, aí você racha ele, tira maduro também, só tira bambu maduro,
            verde ele não cresce, não. Só amarelo.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container section__margin-bottom">
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" md="10" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card borderColor="#426E51">
              <template v-slot:conteudo>
                <p>
                  Segundo a pesquisadora Flávia Galizoni (2005, p. 28), que
                  estudou diversas comunidades rurais de Minas Gerais, as
                  fronteiras entre o natural e o cultural são mediadas pelas
                  formas organizadas de conhecimento que “promovem a união entre
                  a base natural e a elaboração cultural realizada sobre ela: o
                  saber”. Assim, para além dos resultados práticos, importa
                  destacar a centralidade do domínio sobre espécies vegetais,
                  ciclos naturais, ferramentas e técnicas que fazem dos quintais
                  espaços de aprendizado, experimentação e expressão do trabalho
                  enquanto labor e valor. Relembrando o trabalho da construção
                  da cerca de bambu ao entorno da horta, uma atingida de
                  Paracatu de Baixo nos relatou:
                </p>
              </template>
            </Card>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="container section__margin-bottom">
      <v-row class="d-flex justify-center align-center">
        <v-col
          cols="10"
          md="9"
          class="d-flex justify-center align-center border__image"
        >
          <p style="z-index: 15; text-align: center" class="ma-0">
            Eu estava conversando com ela [com a vizinha], o espaço que ela
            tinha era muito grande e ela fez uma cerca de bambu toda em volta
            dessa horta dela. E ela fez sozinha. Ela cortou o bambu, ela
            carregou e depois foi colocando cada uma. E assim tudo certinho,
            aquela cerca que você e olha e fala assim: Que cerca linda!
            Impressionante. Impressionante o trabalho dela.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container">
      <v-row class="d-flex justify-center align-center">
        <v-col
          cols="12"
          md="6"
          class="d-flex justify-center align-center flex-column"
        >
          <img height="100%" src="../../assets/conteudos_casas_27.png" />
          <p style="text-align: center; font-size: 0.6em;" class="mt-1">
            Cerca de Bambu em torno da plantação. Foto: Acervo Gesta, 2017.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container section__margin-bottom">
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" md="10" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card borderColor="#426E51">
              <template v-slot:conteudo>
                <p>
                  As relações sociais organizadas em torno do alimento
                  demonstram que o ato de se alimentar não se baseia apenas nas
                  necessidades nutricionais, mas implica “valoração simbólica”
                  (MENASCHE, 2004). Nesse sentido, durante o evento “A Renovação
                  do desastre”, em julho de 2021, uma moradora de Paracatu de
                  Baixo relatou sobre o sistema formiguinha que era realizado a
                  partir da visita de parentes que viviam na cidade.
                </p>
              </template>
            </Card>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="container section__margin-bottom">
      <v-row class="d-flex justify-center align-center">
        <v-col
          cols="10"
          md="9"
          class="d-flex justify-center align-center border__image"
        >
          <p style="z-index: 15; text-align: center" class="ma-0">
            A gente falava que era sistema de formiguinha, que ia na horta tinha
            couve, tinha verdura, tinha fruta, então você fazia o seu pacote e
            ia nos vizinhos também, às vezes, era bolo, era broa, era doce.
            Então, você voltava abastecido para a cidade. Ia para a empresa
            trabalhar e lá já tinha o alimento. Então, chegava em casa e aquilo
            ali, você gastava pouco. Hoje não, os pais como estão aqui na
            cidade, os filhos vão para casa. [...] Então, além dele [os pais]
            não ter mais essa fartura, de qualidade, porque a comida da horta
            era saudável, não tinha agrotóxico. Compra sim em Mariana, muito
            caro, a despesa aumentou demais da conta também (...). A renda
            familiar ficou num preço muito, muito alto.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container section__margin-bottom">
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" md="7" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card borderColor="#426E51" titleColor="#426E51">
              <template v-slot:conteudo>
                <p>
                  Ao descrever o sistema formiguinha, foi apontada a
                  possibilidade de acessar a casa do vizinho para complementar o
                  “pacote” de alimentos levados pelos filhos em suas visitas à
                  localidade. Esse costume permite refletir sobre a centralidade
                  da reciprocidade entre os núcleos de vizinhança através dos
                  alimentos. O relato também chama a atenção para a ruptura
                  dessas práticas e laços em função do deslocamento compulsório.
                  Nas moradias provisórias na sede urbana de Mariana, os
                  atingidos e atingidas enfrentam o aumento do custo de vida e a
                  impossibilidade de produzir e oferecer fartura de alimentos
                  saudáveis e variados aos filhos. Desse modo, com o sistema
                  formiguinha comprometido, as despesas familiares foram
                  ampliadas: “agora tudo é comprado, se lá a gente não tiver
                  dinheiro e quiser comer uma folha de couve a gente não come.
                  Tudo você tem que ter dinheiro. Se quiser uma folha de cebola
                  tem que comprar” (GESTA, 2017).
                </p>
              </template>
            </Card>
          </div>
        </v-col>
        <v-col
          cols="12"
          md="5"
          class="d-flex justify-center align-center flex-column"
        >
          <img height="100%" src="../../assets/conteudos_casas_28.png" />
          <p style="text-align: center; font-size: 0.6em;" class="mt-1">
            Plantações de maxixe, quiabo e abóbora. Fonte: Acervo Gesta, 2017.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container section__margin-bottom">
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" md="10" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card borderColor="#426E51">
              <template v-slot:conteudo>
                <p>
                  As criações (galinhas, porcos, gado) também estavam presentes
                  em vários quintais e, através de uma sábia organização do
                  consumo e disposição dos produtos, os alimentos cultivados
                  também eram destinados à manutenção dos animais, garantindo,
                  assim, certa internalização dos custos do processo produtivo,
                  como relata uma moradora de Paracatu de Baixo: “tratava [as
                  galinhas], assim, com folha velha, né? Couve, alface, que a
                  gente aproveitava já colocava pra galinha” (GESTA, 2017). Além
                  de constituir importante reserva de valor, as criações
                  garantiam que o cardápio familiar fosse composto por uma
                  variedade de nutrientes: “Os porcos era só pra despesa que a
                  gente tinha, quando ele era muito grande, a gente vendia pros
                  amigos, um pernil..., porque a minha família era grande, então
                  sempre dava para os irmãos da gente um pedacinho” (GESTA,
                  2017).
                </p>
                <p>
                  Para além das produções, o quintal geralmente abriga o
                  <router-link
                    tag="a"
                    style="text-decoration: none"
                    class="pink--text"
                    :to="{ name: 'conteudo_soberania_beira_fogao' }"
                    >fogão a lenha</router-link
                  >
                  e o forno de barro, importantes peças para a cozinha mineira e
                  que atualmente são motivo de disputa nos
                  <router-link
                    class="pink--text"
                    style="text-decoration: none;"
                    :to="{ name: 'conteudos_tematicos_luta_continua' }"
                    target="_blank"
                    >reassentamentos</router-link
                  >
                  de Bento Rodrigues e Paracatu de Baixo. Em volta da casa e
                  espalhados pelo quintal, eram vistos canteiros de flores e
                  outras plantas de jardim.
                </p>
                <p>
                  De modo geral, o manejo das plantas ornamentais nos quintais
                  era realizado diariamente de forma manual, executado pelos
                  membros da família. As moradias das comunidades de Mariana,
                  sejam de núcleos urbanos ou de sítios e fazendas, possuem
                  terreiro de chão batido, geralmente onde chega água pura em
                  uma bica para lavar as panelas, vasilhas ou roupas na tarimba
                  ou jirau (estrado feito geralmente com varas de bambu).
                </p>
              </template>
            </Card>
          </div>
        </v-col>
      </v-row>
    </section>

    <section>
      <v-row class="d-flex justify-center align-center">
        <v-col
          cols="12"
          md="4"
          class="d-flex justify-center align-center flex-column"
        >
          <img src="../../assets/conteudos_casas_29.png" alt="" />
          <p style="text-align: center; font-size: 0.6em;">
            Vista de um jardim situado ao lado da casa, em um terreno de Pedras.
            Foto: Vistoria técnica realizada pela Cáritas, 2019.
          </p>
        </v-col>
        <v-col
          cols="12"
          md="4"
          class="d-flex justify-center align-center flex-column"
        >
          <img src="../../assets/conteudos_casas_30.png" alt="" />
          <p style="text-align: center; font-size: 0.6em;">
            Bica d’água situada em um quintal de terreno de Pedras. Foto:
            Vistoria técnica realizada pela Cáritas, 2019.
          </p>
        </v-col>
        <v-col
          cols="12"
          md="4"
          class="d-flex justify-center align-center flex-column"
        >
          <img src="../../assets/conteudos_casas_31.png" alt="" />
          <p style="text-align: center; font-size: 0.6em;">
            Quintal de uma casa em Campinas, com os varais repletos de roupas
            secando ao sol. Foto: Vistoria técnica realizada pela Cáritas, 2019.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container section__margin-bottom">
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" md="7" class="d-flex justify-center align-center">
          <div class="d-flex justify-center align-center">
            <Card borderColor="#426E51" titleColor="#426E51">
              <template v-slot:conteudo>
                <p>
                  Conforme foi possível apreender, as atividades desenvolvidas
                  nos quintais estavam alicerçadas na organização social das
                  comunidades, o que possibilitava, por exemplo, o acesso à
                  variedade e autonomia na produção e distribuição dos alimentos
                  ali produzidos. Desde o rompimento da barragem de Fundão,
                  muitas famílias vivenciam o desastre através do deslocamento
                  compulsório para o centro urbano de Mariana. A alimentação
                  dessas famílias foi condicionada aos recursos monetários
                  disponíveis (o que limita o acesso e a variedade). E a
                  insegurança quanto à procedência e qualidade dos alimentos
                  passou a existir juntamente com rupturas nas relações sociais
                  mediadas pela alimentação. Além disso, os quintais eram locais
                  de relações, exprimiam os modos de vida e cultura das
                  comunidades. Era comum que se festejassem casamentos,
                  batizados e aniversários neste lugar central para a
                  socialização das famílias e da vida nas comunidades. Retratam,
                  portanto, uma maneira de viver, algo que tem se transformado
                  diante da dinâmica e adaptação a esse processo de
                  desterritorialização.
                </p>
                <p>
                  <strong>
                    Autoria: Ana Paula Alves Ferreira<sup>2</sup>, Maryellen
                    Milena de Lima<sup>3</sup>, Raquel Oliveira Santos
                    Teixeira<sup>4</sup>
                  </strong>
                </p>
              </template>
            </Card>
          </div>
        </v-col>
        <v-col
          cols="12"
          md="5"
          class="d-flex justify-center align-center flex-column"
        >
          <img height="100%" src="../../assets/conteudos_casas_32.png" />
          <p style="text-align: center; font-size: 0.6em;" class="mt-1">
            Quintal de um terreno em Pedras, onde se observa a casa à esquerda
            da imagem e o paiol seguido do galinheiro, cercado por árvores
            frutíferas, à direita. Foto: Vistoria realizada pela Cáritas, 2019.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container pb-10 mt-4">
      <v-row class="d-flex">
        <v-col cols="12" class="d-flex flex-column justify-center align-center">
          <div class="inside__card">
            <p>
              <sup>1</sup> Desde 2017 estamos acompanhando no âmbito do ensino,
              aprendizado, pesquisa e extensão o processo de reparação dos danos
              aos atingidos e atingidas pelo rompimento da barragem de Fundão,
              em Mariana, junto à equipe de pesquisadores do GESTA/UFMG (Grupo
              de Estudos em Temáticas Ambientais). Boa parte dos trechos de
              falas compartilhados ao longo deste texto fazem parte do trabalho
              da Cartografia Social desenvolvida pelo Gesta junto com 30
              famílias da comunidade de Paracatu de Baixo. O trabalho consistiu
              no registro das memórias sobre o território afetado através de
              oficinas e campanhas de campo.
            </p>
            <p>
              <sup>2</sup> Arquiteta e Urbanista pela USP. Assessora Técnica na
              ATI Mariana.
            </p>
            <p>
              <sup>3</sup> Pesquisadora GESTA/UFMG, mestranda em Sociedade,
              Ambiente e Território (UFMG/Unimontes).
            </p>
            <p class="mb-0">
              <sup>4</sup> Pesquisadora GESTA/UFMG, professora Adjunta do
              Departamento de Sociologia UFMG.
            </p>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="container pb-10 mt-4">
      <v-row class="d-flex">
        <v-col cols="12" class="d-flex flex-column justify-center align-center">
          <div class="inside__card">
            <p>
              <strong>Referências Bibliográficas</strong>
            </p>
            <p>
              GALIZONI, Flávia Maria; RIBEIRO, Eduardo Magalhães. Água, terra e
              família: uma etnografia dos recursos hídricos nas comunidades
              camponesas da mantiqueira mineira. <strong>Rev. UFMG</strong>,
              Belo Horizonte, v. 20, n.2, p. 68-93, jul./dez. 2013.
            </p>
            <p>
              LIMA, Maryellen Milena. “Aqui não é o nosso lugar”: Efeitos
              socioambientais, a vida provisória e o processo de (re)construção
              de Paracatu de Baixo, Mariana/MG.
              <strong>Monografia</strong> (Bacharelado em Ciências
              Socioambientais) — Faculdade de Filosofia e Ciências Humanas da
              Universidade Federal de Minas Gerais. Belo Horizonte, 2018.
            </p>
            <p class="mb-0">
              MENASCHE, Renata. Risco à mesa: alimentos transgênicos, no meu
              prato não? <strong>Campos</strong>, 2004, n. 5, v. 1, pp. 93-110.
            </p>
          </div>
        </v-col>
      </v-row>
    </section>

    <section class="container">
      <v-row class="d-flex justify-center align-center pa-4">
        <v-col class="d-flex justify-center align-center">
          <v-btn
            :to="{ name: 'conteudos_tematicos_casas_tecnicas_construtivas' }"
            color="#EDBD43"
          >
            <strong>Conhecer “Técnicas construtivas”</strong>
          </v-btn>
        </v-col>
      </v-row>
    </section>
  </div>
</template>

<script>
import Card from "@/components/Card";
import Breadcrumb from "@/layout/Breadcrumb";

export default {
  components: { Card, Breadcrumb },
  data() {
    return {
      breadcrumbItems: [
        {
          text: "< Conteúdos Temáticos /",
          href: "conteudos_tematicos",
        },
        {
          text: "Casas /",
          href: "conteudos_tematicos_casas",
        },
        {
          text: "Os Jardins e Quintais",
          href: "conteudos_tematicos_casas_jardins_quintais",
        },
      ],
    };
  },
};
</script>

<style scoped>
.section__margin-bottom {
  margin-bottom: 3em;
}

.pointer {
  cursor: pointer;
}

@media (max-width: 600px) {
  section {
    margin-top: 0;
    margin-bottom: 0;
  }
  .border__image {
    background-color: rgba(237, 189, 67, 0.3);
    padding: 0;
    border-radius: 12px;
  }
  .border__image::before {
    display: none;
  }
  .border__image p {
    padding: 0 !important;
    width: 100% !important;
    font-size: 0.7em !important;
  }
}

.inside__card {
  background-color: white;
  width: 100%;
  padding: 1em;
}

.inside__card p {
  font-size: 0.5em;
}

.border__image {
  width: 100%;
  padding: 2em;
  position: relative;
}

.border__image::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: -0.6em;
  left: 0;
  background-image: url("../../assets/conteudos_casas_vector99.png");
  background-size: 100% 100%;
}

.border__image p {
  font-size: 0.6em;
  max-width: 80%;
  padding-right: 5em;
}
.border__image h3 {
  color: #e06919;
  font-size: 0.5em;
}
</style>
